<template>
  <v-container
    id="data-tables-view"
    fluid
    tag="section"
  >
    <material-card
      icon="mdi-clipboard-list"
      icon-small
      color="primary"
      title="Device logs"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="logs"
          :options.sync="options"
          :search.sync="search"
          :server-items-length="total"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [5, 10, 15],
          }"
        >
          <template v-slot:top>
            <v-dialog
              v-model="dialogDelete"
              max-width="500px"
            >
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogDelete = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.errorType`]="{ item }">
            <div>{{ getErrorTypeName(item) }}</div>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <div>{{ dateFormat(item.createdAt) }}</div>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
  import SettingsDataService from '../services/SettingsDataService'
  import moment from 'moment'

  export default {
    name: 'DeviceLogs',
    data: () => ({
      dialogDelete: false,
      options: {},
      total: 0,
      loading: false,
      headers: [
        {
          text: 'MacAddress',
          value: 'deviceId',
        },
        {
          text: 'Log',
          value: 'log',
        },
        {
          text: 'Created at',
          value: 'createdAt',
        },
      ],
      logs: [],
      awaitingSearch: false,
      search: '',
    }),

    computed: {},
    watch: {
      options: {
        handler () {
          this.getDataFromApi(this.search)
        },
        deep: true,
      },
      search: {
        handler () {
          if (!this.awaitingSearch) {
            setTimeout(() => {
              this.getDataFromApi(this.search)
              this.awaitingSearch = false
            }, 1000) // 1 sec delay
          }
          this.awaitingSearch = true
        },
      },
    },
    created () {
    // this.getDataFromApi();
    },

    methods: {
      getDataFromApi (search) {
        this.loading = true
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        var data = {
          Start: itemsPerPage * (page - 1),
          Length: itemsPerPage,
          Search: search,
          SortColumnName: 'Created',
          SortDirection: 'desc',
        }
        SettingsDataService.deviceLogs(data).then((response) => {
          this.logs = response.data.data.errors
          this.total = response.data.data.totalErrors
          this.loading = false
        })
      },

      // deleteItemConfirm() {
      //   OrdersDataService.removeCsvErrors().then((response) => {
      //     if (response.status === 200) {
      //       this.$toast.success(
      //         "Csv error logs removed successfully.",
      //         "Success",
      //         {
      //           position: "topRight",
      //         }
      //       );
      //       this.getDataFromApi(this.search);
      //     }
      //   });

      //   this.dialogDelete = false;
      // },
      dateFormat (date) {
        if (date) {
          return moment.utc(date).local().format('h:mm:ss a DD.MM.YYYY')
        } else {
          return ''
        }
      },
      getErrorTypeName (item) {
        const errorType = this.errorTypes.find((x) => x.id == item.errorType)
        if (errorType) {
          return errorType.text
        }
        return ''
      },
    },
  }
</script>
